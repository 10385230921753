import { Col, Collapse, DatePicker, Form, Input, Row } from "antd"
// import { Editor } from 'draft-js';
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { dataEditor, itemsCollapse } from "./tagData"
import { StyleFileBM01 } from "../../styled"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import FormService from "src/services/FormSevice"
import moment from "moment"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"
import STORAGE, { getStorage } from "src/lib/storage"
import { ContentState, EditorState } from "draft-js"
import { RenderTiny } from "src/components/TinyEditor"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import ReusableEditor from "src/components/ReusableEditor"
const FileBM01 = ({ setStatusRules, next }) => {
  const userInfo = getStorage(STORAGE.USER_INFO)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { RangePicker } = DatePicker
  const dispatch = useDispatch()
  const [dataDate, setDataDate] = useState(null)
  const [Pdf, setPDF] = useState()
  const { id } = useParams()

  const onChangeCollap = key => {
    console.log(key)
  }
  const handleDateChange = dates => {
    setDataDate(dates)
  }
  const getDetailsForm = async () => {
    try {
      setLoading(true)

      const resp = await FormService.getFormByID({
        practise_id: id,
        form_type: 1,
      })

      form.setFieldsValue({
        ...resp.data.form_log,
        date: [
          resp.data.form_log.from_date
            ? dayjs(resp.data.form_log.from_date)
            : undefined,
          resp.data.form_log.to_date
            ? dayjs(resp.data.form_log.to_date)
            : undefined,
        ],
        rehearsalTime: [
          resp.data.form_log.start_date
            ? dayjs(resp.data.form_log.start_date)
            : undefined,
          resp.data.form_log.end_date
            ? dayjs(resp.data.form_log.end_date)
            : undefined,
        ],
      })
      const updatedEditorStates = {}
      Object.keys(resp.data.form_log).forEach(key => {
        const text = resp.data.form_log[key]
        updatedEditorStates[key] = EditorState.createWithContent(
          ContentState.createFromText(
            text && typeof text === "string" ? text : "",
          ),
        )
      })
    } finally {
      setLoading(false)
    }
  }
  const handleSubmitBM02 = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      const body = {
        ...values,
        from_date: !!values?.date
          ? values?.date[0]?.format("YYYY-MM-DD HH:mm")
          : undefined,
        to_date: !!values?.date
          ? values?.date[1]?.format("YYYY-MM-DD HH:mm")
          : undefined,
        start_date: !!values?.rehearsalTime
          ? values?.rehearsalTime[0]?.format("YYYY-MM-DD HH:mm")
          : undefined,
        end_date: !!values?.rehearsalTime
          ? values?.rehearsalTime[1]?.format("YYYY-MM-DD HH:mm")
          : undefined,

        practise_id: id,
        user_id: userInfo?.id,
      }
      const res = await FormService.PostForm1(body)
      if (res.isOk) {
        getDetailsForm()
        next()
        Notice({ isSuccess: true, msg: res.message })
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDetailsForm()
  }, [])
  return (
    <StyleFileBM01>
      <Form form={form} layout="vertical" scrollToFirstError>
        <Row gutter={[16, 0]} className="mr-40">
          <Col span={24}>
            <h2 className="tile-01">BM01: Nội quy diễn tập thực chiến</h2>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
              label="Tên cơ quan"
              name="orginazation_name"
            >
              <Input placeholder="Nhập tên cơ quan"></Input>
            </Form.Item>
          </Col>
          {/* <Col span={8}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Thông tin không được để trống",
                            },
                        ]}
                        label="Sổ QD"
                        name="number_QD"
                    >
                        <Input placeholder='Nhập'></Input>
                    </Form.Item>
                </Col> */}
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
              label="Sổ QĐ"
              name="number_QD"
            >
              <Input placeholder="Nhập"></Input>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
              label="Địa điểm ban hành"
              name="place_of_issuance"
            >
              <Input placeholder="Nhập"></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
              label="Thời gian ban hành"
              name="date"
            >
              <RangePicker value={dataDate} onChange={handleDateChange} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
              label="Hiệu lực thi hành"
              name="effect"
            >
              <Input placeholder="Nhập"></Input>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
              label="Tên đơn vị đề xuất"
              name="suggested_unit"
            >
              <Input placeholder="Nhập"></Input>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
              label="Thủ trưởng đơn vị"
              name="leader_of_unit"
              trigger="onChange" // Changed to "onChange"
              validateTrigger={["onChange"]} // Changed to "onChange"
            >
              {/* <RenderTiny
                setLoading={setLoading}
                value={form.getFieldValue("leader_of_unit")}
                onChange={content =>
                  form.setFieldsValue({ leader_of_unit: content })
                }
              /> */}
              <ReusableEditor
                value={form.getFieldValue("leader_of_unit")}
                onChange={content =>
                  form.setFieldsValue({ leader_of_unit: content })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              // rules={[
              //     {
              //         required: true,
              //         message: "Thông tin không được để trống",
              //     },
              // ]}
              label="Nơi nhận"
              name="place_of_receipt"
            >
              <ReusableEditor
                value={form.getFieldValue("place_of_receipt")}
                onChange={content =>
                  form.setFieldsValue({ place_of_receipt: content })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              // rules={[
              //     {
              //         required: true,
              //         message: "Thông tin không được để trống",
              //     },
              // ]}
              label="Kèm theo quyết định số"
              name="attached_decision"
            >
              <Input placeholder="Nhập"></Input>
            </Form.Item>
          </Col>
          <Col span={24} style={{ fontWeight: "600" }}>
            Điều 1: XÁC ĐỊNH GIỚI HẠN VÀ CÁCH THỨC TỔ CHỨC DIỄN TẬP
          </Col>
          <Col
            span={24}
            style={{
              paddingBottom: "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ fontWeight: "600" }}>
              1. Mục tiêu diễn tập{" "}
              <span
                style={{ color: "red", fontStyle: "16px", margin: "0 5px" }}
              >
                {" "}
                *{" "}
              </span>{" "}
            </span>
            <span style={{ color: "grey", fontStyle: "italic" }}>
              (Mô tả chi tiết từng hệ thống gồm: Tên hệ thống, địa chỉ IP, tên
              miền vị trí và chức năng hệ thống)
            </span>
          </Col>
          <Col span={24} className="paddingBottom0">
            <Form.Item
              name="target"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
              trigger="onEditorChange"
              validateTrigger={["onEditorChange"]}
            >
              <ReusableEditor
                value={form.getFieldValue("target")}
                onChange={content => form.setFieldsValue({ target: content })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
              label="2. Thời gian diễn tập"
              name="rehearsalTime"
            >
              <RangePicker
                showTime={{
                  format: "HH:mm",
                }}
                format="YYYY-MM-DD HH:mm"
                value={dataDate}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              // rules={[
              //     {
              //         required: true,
              //         message: "Thông tin không được để trống",
              //     },
              // ]}
              label="3. Số đội tấn công"
              name="attack_number_team"
            >
              <Input placeholder="Nhập"></Input>
            </Form.Item>
          </Col>
          <Col
            span={24}
            style={{
              paddingBottom: "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ fontWeight: "600" }}>
              4. Các hành vi và phương thức tấn công bị cấm sử dụng trong diễn
              tập
              <span
                className="ml-6"
                style={{ color: "grey", fontStyle: "italic" }}
              >
                (Ghi rõ số thứ tự, tên và phương thức bị cấm)
              </span>
            </span>
          </Col>

          <Col span={24} className="paddingBottom0">
            <Form.Item
              // label={(
              //     <div  >
              //         <div style={{ fontWeight: 'bold', width: '100%' }}>4. Các hành vi và phương thức tấn công bị cấm sử dụng trong diễn tập</div>
              //         <div>Ghi rõ số thứ tự, tên và phương thức bị cấm</div>
              //     </div>
              // )}
              name="taboo"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
            >
              {/* <div style={{ paddingLeft: "15px" }}>Mô tả chi tiết từng hệ thống gồm: Tên hệ thống, địa chỉ IP, tên miền vị trí và chức năng hệ thống</div> */}
              <ReusableEditor
                value={form.getFieldValue("taboo")}
                onChange={content => form.setFieldsValue({ taboo: content })}
              />
            </Form.Item>
          </Col>

          <Col span={24} className="paddingBottom0">
            <Form.Item
              label={
                <div>
                  <div style={{ fontWeight: "600", width: "100%" }}>
                    5. Danh sách địa chỉ IP của các đội tham gia thực hiện vai
                    trò Đội tấn công
                  </div>
                </div>
              }
              name="list_ip_attack_team"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
            >
              <ReusableEditor
                value={form.getFieldValue("list_ip_attack_team")}
                onChange={content =>
                  form.setFieldsValue({ list_ip_attack_team: content })
                }
              />
            </Form.Item>
          </Col>
          <Col
            span={24}
            style={{
              paddingBottom: "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ fontWeight: "600" }}>
              6.Danh sách trang thiết bị phòng thủ
              <span
                style={{ color: "red", fontStyle: "16px", margin: "0 5px" }}
              >
                {" "}
                *{" "}
              </span>{" "}
            </span>
            <span style={{ color: "grey", fontStyle: "italic" }}>
              (chỉ dành cho đội phòng thủ)
            </span>
          </Col>
          <Col span={24} className="paddingBottom0">
            <Form.Item
              // label={(
              //     <div  >
              //         <div style={{ fontWeight: '600', width: '100%' }}>6.Danh sách trang thiết bị phòng thủ (<span>chỉ dành cho đội phòng thủ</span>)</div>
              //     </div>
              // )}
              name="list_equipment_defense"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
            >
              <ReusableEditor
                value={form.getFieldValue("list_equipment_defense")}
                onChange={content =>
                  form.setFieldsValue({ list_equipment_defense: content })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ fontWeight: "600" }}>
            ĐIỀU 2: XÁC ĐỊNH CÁC NGUYÊN TẮC CẦN TUÂN THỦ TRONG QUÁ TRÌNH DIỄN
            TẬP
          </Col>
          <Col span={24} className="paddingBottom0">
            <Form.Item
              label={
                <div>
                  <div style={{ fontWeight: "600", width: "100%" }}>
                    1. Nguyên tắc chung
                  </div>
                </div>
              }
              name="rule_general"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
            >
              <ReusableEditor
                value={form.getFieldValue("rule_general")}
                onChange={content =>
                  form.setFieldsValue({ rule_general: content })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24} className="paddingBottom0">
            <Form.Item
              label={
                <div>
                  <div style={{ fontWeight: "600", width: "100%" }}>
                    2. Nguyên tắc phòng thủ
                  </div>
                </div>
              }
              name="rule_defense"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
            >
              <ReusableEditor
                value={form.getFieldValue("rule_defense")}
                onChange={content =>
                  form.setFieldsValue({ rule_defense: content })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24} className="paddingBottom0">
            <Form.Item
              label={
                <div>
                  <div style={{ fontWeight: "600", width: "100%" }}>
                    3. Nguyên tắc tấn công
                  </div>
                </div>
              }
              name="rule_attack"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống",
                },
              ]}
            >
              <ReusableEditor
                value={form.getFieldValue("rule_attack")}
                onChange={content =>
                  form.setFieldsValue({ rule_attack: content })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Collapse
              items={itemsCollapse}
              //   defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
              onChange={onChangeCollap}
            />
          </Col>
          {/* <Col span={24} className='d-flex'>
                    <Button btnType="primary" className='mr-40' > Ký tên </Button>
                    <Button btnType="primary"> Đóng dấu</Button>
                </Col> */}

          <Col span={24} className="d-flex ">
            <div
              style={{
                textAlign: "right",
                marginBottom: "40px",
                marginTop: "40px",
                display: "flex",
                width: "100%",
                padding: "0px 15px 0px 0px",
              }}
            >
              <Button
                className=""
                style={{
                  marginRight: "8px",
                  background: "#248C4E",
                  color: "white",
                }}
                htmlType="submit"
                onClick={handleSubmitBM02}
              >
                Lưu và tiếp tục
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </StyleFileBM01>
  )
}

export default FileBM01
